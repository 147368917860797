<template>
  <div class="viewDashBoard project-route-index">
    <transition
      name="fade"
      mode="out-in"
      @beforeLeave="beforeLeave"
      @enter="enter"
      @afterEnter="afterEnter"
    >
      <div key="1" v-if="loader" class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      <div key="2" v-else class="white-row">
        <div class="show-project-portal-listing">
          <!-- Create Project Form  -->
          <div class="form-area">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-12">
                  <div class="row top-gen-heading">
                    <h1 class="global-style-heading">
                      Edit Product / Service
                    </h1>
                  </div>
                </div>
                <div class="col-sm-12">
                  <form
                    class="status-form"
                    v-on:submit.prevent="onSubmit($event)"
                  >
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="form-group">
                            <label for="client_id"
                              >Select Client: <span>*</span></label
                            >
                            <Select2
                              :options="project_clients"
                              :settings="{
                                placeholder:
                                  'Select Client',
                                allowClear: true
                              }"
                              v-model="formData.client_id"
                              :class="{
                                'input-border-errors':
                                  $v.formData.client_id.$dirty &&
                                  !$v.formData.client_id.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="form-group">
                            <label for="product_service_name"
                              >Product / Service Name: <span>*</span></label
                            >
                            <Select2
                              @change="ResetValues"
                              :options="product_service_options"
                              :settings="{
                                placeholder:
                                  'Product / Service Name',
                              }"
                              v-model="formData.product_service_name"
                              :class="{
                                'input-border-errors':
                                  $v.formData.product_service_name.$dirty &&
                                  !$v.formData.product_service_name.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6"
                        v-if="(formData.product_service_name == 'Domain') || 
                        (formData.product_service_name == 'Hosting') ||
                        (formData.product_service_name == 'SSL Certificate') ||
                        (formData.product_service_name == 'Maintenance') ||
                        (formData.product_service_name == 'Domain And Hosting') "
                      >
                        <div class="row">
                          <div class="form-group">
                            <label for="domain_url"
                              >Domain Url: <span>*</span></label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Domain Url"
                              v-model="formData.domain_url"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6"
                        v-if="(formData.product_service_name == 'Domain') || 
                        (formData.product_service_name == 'Hosting') ||
                        (formData.product_service_name == 'SSL Certificate') ||
                        (formData.product_service_name == 'Maintenance') ||
                        (formData.product_service_name == 'Domain And Hosting') "
                      >
                        <div class="row">
                          <div class="form-group">
                            <label for="domain_hosting_provider"
                              >Domain / Hosting Provider: <span>*</span> </label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Domain / Hosting Provider"
                              v-model="formData.domain_hosting_provider"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- For Marketing  -->
                      <div class="col-sm-6"
                        v-if="(formData.product_service_name == 'Marketing')"
                      >
                        <div class="row">
                          <div class="form-group">
                            <label for="marketing_package_name"
                              >Marketing package name: <span>*</span></label
                            >
                            <Select2
                              :options="marketing_package_options"
                              :settings="{
                                placeholder:
                                  'Marketing package name',
                                allowClear: true
                              }"
                              v-model="formData.marketing_package_name"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- Marketing Fields end  -->

                      <div class="col-sm-6">
                        <div class="row">
                          <div class="form-group custom-date-picker">
                            <label for="starting_date">Starting Date</label>
                            <datepicker
                              :class="{
                                'input-border-errors':
                                  $v.formData.starting_date.$dirty &&
                                  !$v.formData.starting_date.required
                              }"
                              :minimumView="'day'"
                              :maximumView="'month'"
                              :initialView="'month'"
                              :format="customFormatterStartingdate"
                              placeholder="Starting Date"
                              :input-class="'form-control'"
                              :clear-button="true"
                              :value="formData.starting_date"
                              @cleared="onClearDateStartingFromDate"
                            >
                              <span
                                slot="afterDateInput"
                                class="animated-placeholder"
                              >
                                <i class="far fa-calendar-alt"></i>
                              </span>
                            </datepicker>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="form-group custom-date-picker">
                            <label for="expiry_date">Expiry Date</label>
                            <datepicker
                              :class="{
                                'input-border-errors':
                                  $v.formData.expiry_date.$dirty &&
                                  !$v.formData.expiry_date.required
                              }"
                              :minimumView="'day'"
                              :maximumView="'month'"
                              :initialView="'month'"
                              :format="customFormatterExpirydate"
                              placeholder="Expiry Date"
                              :input-class="'form-control'"
                              :clear-button="true"
                              :value="formData.expiry_date"
                              @cleared="onClearExpiryDateFromDate"
                            >
                              <span
                                slot="afterDateInput"
                                class="animated-placeholder"
                              >
                                <i class="far fa-calendar-alt"></i>
                              </span>
                            </datepicker>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6"
                        v-if="(formData.product_service_name == 'Domain') || 
                        (formData.product_service_name == 'Hosting') ||
                        (formData.product_service_name == 'Domain And Hosting') "
                      >
                        <div class="row">
                          <div class="form-group">
                            <label for="do_we_have_maintenance"
                              >Do we have maintenance plan:</label
                            >
                            <Select2
                              :options="maintenance_plan_options"
                              :settings="{
                                placeholder:
                                  'Do we have maintenance plan',
                                allowClear: true
                              }"
                              v-model="formData.do_we_have_maintenance"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6"
                        v-if="(formData.product_service_name == 'Domain') || 
                        (formData.product_service_name == 'Hosting') ||
                        (formData.product_service_name == 'Domain And Hosting') "
                      >
                        <div class="row">
                          <div class="form-group">
                            <label for="do_we_have_maintenance"
                              >Do we have SSL for hosting or Domain:</label
                            >
                            <Select2
                              :options="maintenance_plan_options"
                              :settings="{
                                placeholder:
                                  'Do we have SSL for hosting or Domain',
                                allowClear: true
                              }"
                              v-model="formData.do_we_have_ssl"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="form-group">
                            <label for="amount"
                              >Amount: <span>*</span></label
                            >
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Amount"
                              v-model="formData.amount"
                              :class="{
                                'input-border-errors':
                                  $v.formData.amount.$dirty &&
                                  !$v.formData.amount.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="form-group">
                            <label for="currency_id" class="control-label"
                              >Currency <span>*</span>
                            </label>
                            <Select2
                              v-model="formData.currency_id"
                              :options="currency_options"
                              :settings="{
                                placeholder: 'Select Currency'
                              }"
                              :class="{
                                'input-border-errors':
                                  $v.formData.currency_id.$dirty &&
                                  !$v.formData.currency_id.required
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="form-group">
                            <label for="status" class="control-label"
                              >Status <span>*</span>
                            </label>
                            <Select2
                              v-model="formData.status"
                              :options="status_options"
                              :settings="{
                                placeholder: 'Status'
                              }"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="form-group">
                            <label for="additional_data" class="control-label">Additional Info</label>
                            <ckeditor
                              v-model="formData.additional_data"
                              :config="editorConfig"
                            ></ckeditor>
                          </div>
                        </div>
                      </div>
                      <!-- <div v-if="formData.product_service_name == 'domain'">

                      </div> -->
                      <!-- <div class="col-sm-12">
                        <div class="row">
                          <div class="form-group">
                            <label for="gender" class="control-label"
                              >Select Project Status Category Name:
                              <span>*</span></label
                            >
                            <Select2
                              :options="status_category_option"
                              :settings="{
                                placeholder:
                                  'Select Project Status Category Name',
                                allowClear: true
                              }"
                              v-model="formData.status_category_id"
                              :class="{
                                'input-border-errors':
                                  $v.formData.status_category_id.$dirty &&
                                  !$v.formData.status_category_id.required
                              }"
                            />
                          </div>
                        </div>
                      </div> -->


                      <div class="col-sm-12">
                        <div class="row">
                          <div class="form-actions">
                            <button
                              :disabled="disableSubmitBtn"
                              type="submit"
                              id="save-form"
                              class="btn btn-primary default-global-btn"
                            >
                              Update
                            </button>
                            <div
                              v-if="disableSubmitBtn"
                              class="form_submit_loader"
                            >
                              <div class="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Select2 from "v-select2-component";
import { validationMixin } from "vuelidate";
import Datepicker from "vuejs-datepicker";
const {
  required,
  minLength,
  email,
  numeric,
  url
} = require("vuelidate/lib/validators");
export default {
  mixins: [validationMixin],
  components: {
    Select2,
    Datepicker
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  validations: {
    formData: {
      client_id: {
        required
      },
      product_service_name: {
        required
      },
      starting_date: {
        required
      },
      expiry_date: {
        required
      },
      currency_id : {
        required
      },
      amount : {
        required
      },
    }
  },
  data() {
    return {
      loader: true,
      formData: {
        id : null,
        client_id : null,
        product_service_name: 'Domain',
        domain_url : null,
        domain_hosting_provider : null,
        starting_date: null,
        expiry_date: null,
        do_we_have_maintenance : 'no',
        additional_data : null,
        do_we_have_ssl : 'no',
        // For Marketing
        marketing_package_name : null,
        amount : null,
        currency_id : null,
        status : null,
        timezone : Intl.DateTimeFormat().resolvedOptions().timeZone,
        
      },
      disableSubmitBtn: false,
      product_service_options : [
        {
          id: "SSL Certificate",
          text: "SSL Certificate"
        },
        {
          id : 'Domain',
          text : 'Domain',
        },
        {
          id : 'Hosting',
          text : 'Hosting',
        },
        {
          id : 'Domain And Hosting',
          text : 'Domain And Hosting',
        },
        {
          id : 'Marketing',
          text : 'Marketing',
        },
        {
          id : 'Maintenance',
          text : 'Maintenance',
        }
      ],
      maintenance_plan_options : [
        {
          id : 'yes',
          text : 'Yes',
        },
        {
          id : 'no',
          text : 'No',
        },
      ],
      status_options : [
        {
          id : 'active',
          text : 'Active',
        },
        {
          id : 'expired',
          text : 'Expired',
        },
        {
          id: "canceled",
          text: "Canceled"
        },
        {
          id: "suspended",
          text: "Suspended"
        }
      ],
      marketing_package_options : [
        {
          id : 'Foundational',
          text : 'Foundational',
        },
        {
          id : 'Core',
          text : 'Core',
        },
        {
          id : 'E-commerce',
          text : 'E-commerce',
        },
      ],
      project_clients : null,
      editorConfig: {
        toolbarGroups: [
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi"]
          },
          { name: "links" },
          { name: "styles" },
          { name: "colors" },
          { name: "tools" },
          { name: "others" },
          { name: "about" }
        ],
        // The configuration of the editor.
        extraPlugins: "font",
        versionCheck: false,
      },
      currency_options: null,
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
    this.getclients();
    this.getCurrency();
    this.getProductServiceDetails();
    this.loader = false;
  },
  methods: {
    async getProductServiceDetails() {
      // alert("hi");
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "product-service/edit/" + this.$route.params.product_service_id
      });
      await data.then(function(response) {
        if (typeof response != "undefined") {
          $this.formData.id = response.data.data.id;
          $this.formData.client_id = response.data.data.client_id;
          $this.formData.product_service_name= response.data.data.product_service_name;
          $this.formData.domain_url = response.data.data.domain_url;
          $this.formData.domain_hosting_provider = response.data.data.domain_hosting_provider;
          $this.formData.starting_date= response.data.data.starting_date;
          $this.formData.expiry_date= response.data.data.expiry_date;
          $this.formData.do_we_have_maintenance = response.data.data.do_we_have_maintenance_plan;
          $this.formData.additional_data = response.data.data.additional_notes;
          $this.formData.do_we_have_ssl = response.data.data.ssl_status;
          // For Marketing
          $this.formData.marketing_package_name = response.data.data.marketing_package_name;
          $this.formData.amount = response.data.data.amount;
          $this.formData.currency_id = response.data.data.currency_id;
          $this.formData.status = response.data.data.status;
        }
      });
    },
    async getCurrency() {
      const $this = this;
      const currnecy = $this.$store.dispatch("getHttp", {
        url: "filters/get-curriens"
      });
      await currnecy.then(function(response) {
        if (typeof response !== "undefined") {
          $this.currency_options = response.data.data;
        }
      });
    },
    ResetValues(val) {
      if((val == 'Domain') || (val == 'Hosting') || (val == 'Doamin And Hosting') ) {
        this.formData.marketing_package_name = null;
        this.formData.marketing_budget = null;
      } else {
        this.formData.domain_url = null;
        this.formData.domain_hosting_provider = null;
        this.formData.do_we_have_maintenance = 'no';
        this.formData.do_we_have_ssl = 'no';
      }
    },
    // General Filtesr
    async getclients() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "/filters/get-clients"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.project_clients = response.data.data;
        }
      });
    },
    onClearExpiryDateFromDate() {
      this.formData.expiry_date = null;
    },
    onClearDateStartingFromDate(){
      this.formData.starting_date = null;
    },
    customFormatterExpirydate(val) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      var new_val = months[val.getMonth()] +
            " " +
            val.getDate() +
            ", " +
            val.getFullYear();
      this.formData.expiry_date = new_val;
      return new_val;
    },
    customFormatterStartingdate(val) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      var new_val = months[val.getMonth()] +
            " " +
            val.getDate() +
            ", " +
            val.getFullYear();
      this.formData.starting_date = new_val;
      return new_val;
    },
    async onSubmit(event) {
      const $this = this;
      $this.disableSubmitBtn = true;
      $this.$v.$touch();

      if ($this.$v.$invalid) {
        $this.$store.dispatch("showtostrMessageNotifications", {
          message: "Please fill all the fields to proceed form!",
          type: "error"
        });
        $this.disableSubmitBtn = false;
        return;
      }
      const data = $this.$store.dispatch("postHttp", {
        url: "product-service/update",
        formDetails: this.formData,
        formEvent: event.target
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          console.log(response);
          $this.$store.dispatch("displayMessageErrors", {
            response: response,
            formEvent: event.target
          });
          $this.$router.push({ path: "/product-services" });
          $this.disableSubmitBtn = false;
        }
      });
      $this.disableSubmitBtn = false;
    },
    
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    }
  }
};
</script>
<style lang="scss" scoped>
body .form-area .form-group label span {
  color: red;
}
body .white-row.loader-route {
  top: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.project-route-index {
  .show-project-portal-listing {
    .general-filters {
      .form-group {
        width: 100%;
      }
      .form-actions {
        width: 100%;
        button {
          width: 100%;
          margin-left: 0;
          padding: 5px 0;
        }
      }
      span.select2.select2-container.select2-container--default {
        width: 100% !important;
      }
    }
    h4 {
      letter-spacing: 0.28px;
      color: #323f54;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;
      padding-bottom: 7px;
    }
    p {
      margin: 0;
      letter-spacing: 0.28px;
      color: #2a1c0b;
    }
    section {
      border-bottom: 1px solid #ebebeb;
      padding: 30px 0;
      &.client-projects {
        padding-bottom: 0px;
        h4 {
          padding: 5px 0 25px;
        }
        .wrap-box {
          background: #faf9f7 0% 0% no-repeat padding-box;
          border-radius: 3px;
          padding: 20px 15px;
          min-height: 231px;
          margin-bottom: 30px;
          .box {
            position: relative;
            .project-details {
              p {
                &.project-id {
                  background: #ece9df 0% 0% no-repeat padding-box;
                  border-radius: 3px;
                  display: inline-block;
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 4px 10px;
                }
                &.project-status {
                  letter-spacing: 0.24px;
                  color: #323f54;
                  font-size: 12px;
                  padding: 3px 0 15px;
                  font-weight: 400;
                }
              }
              .project-listing {
                padding: 15px 0 15px;
                p {
                  margin: 0;
                  letter-spacing: 0.28px;
                  color: #323f54;
                  font-size: 14px;
                  font-weight: bold;
                  text-transform: inherit;
                  line-height: 25px;
                }
              }
            }
            .footer-box {
              display: block;
              position: relative;
              width: 100%;
              padding: 20px 0 10px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
              .usr-role {
                p {
                  letter-spacing: 0.24px;
                  color: #918a82;
                  font-weight: 300;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
