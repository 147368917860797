import { render, staticRenderFns } from "./admin-vue.vue?vue&type=template&id=795e5dec&scoped=true&"
import script from "./admin-vue.vue?vue&type=script&lang=js&"
export * from "./admin-vue.vue?vue&type=script&lang=js&"
import style0 from "./admin-vue.vue?vue&type=style&index=0&id=795e5dec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "795e5dec",
  null
  
)

export default component.exports